<template>
  <div class="row" v-if="entity">
    <div class="col col-md-8">
      <div class="column">
        <base-add-button :label="$t('evenements.add_evenement')" color="reseau"
          @click="isFormOpen = true" v-if="!isFormOpen" />

          <q-card flat bordered class="q-pa-sm" v-if="isFormOpen">
            <q-card-section>
              <EvenementForm @reset="isFormOpen = false" @success="onEvenementAdded"
                model_type="entity" :model_id="entity.id" color="reseau" />
            </q-card-section>
          </q-card>

        <EvenementsTimeline :evenements="evenements" color="reseau"
          @updated="fetchEvenements" @delete="deleteEvenement" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EvenementForm, EvenementsTimeline } from '../../components/evenements'

export default {
  name: 'EntityEvenements',
  components: { EvenementForm, EvenementsTimeline },
  props: {
    'form_model': { type: String, default: 'evenements' }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['evenements/getFilters']) {
      store.dispatch('evenements/fetchFilters')
    }
  },

  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      entity: 'entities/getCurrentEntity',
      filters: 'evenements/getFilters',
      getEvenementsFor: 'evenements/getEvenementsFor'
    }),

    evenements () {
      return this.getEvenementsFor('entity', this.entity.id)
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(this.fetchEvenements)
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) {
      this.fetchEvenements()
    }
  },

  methods: {
    onEvenementAdded () {
      this.fetchEvenements()
      this.isFormOpen = false
    },

    fetchEvenements () {
      this.isFormOpen = false

      let params = {}
      this.filters.forEach(filter => {
        if (filter.value !== undefined) params[filter.id] = filter.value
        if (filter.type === 'date') params[filter.id] = this.$formatDate(filter.value, 'YYYY-MM-DD')
      })

      this.$q.loading.show()

      return this.$store.dispatch('evenements/fetchEvenements', { ...params,
        model_type: 'entity',
        model_id: this.$route.params.id,
        rowsPerPage: 0
      }).finally(() => { this.$q.loading.hide() })
    },

    deleteEvenement (id) {
      this.$confirm(this.$t('evenements.delete_evenement_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('evenements/deleteEvenement', id).then(() => {
          this.$q.notify({ message: this.$t(`evenements.delete_success`), color: 'positive' })
          this.fetchEvenements()
        }).catch(this.$notifyError)
          .finally(() => { this.$emit('updated') })
      })
    }
  }
}
</script>

<style>
</style>

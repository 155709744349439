<template>
  <base-timeline :items="timelineEntries" :color="color">
    <template slot-scope="entry">
      <EvenementCard :evenement="entry.item" @menuClick="onMenuClick" />
    </template>
  </base-timeline>
</template>

<script>
import { mapGetters } from 'vuex'
import { EvenementCard } from './'

export default {
  name: 'EvenementsTimeline',
  props: {
    evenements: { type: Array, default: () => ([]) },
    color: { type: String }
  },
  components: { EvenementCard },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      favorites: 'favorites/getFavorites'
    }),

    timelineEntries () {
      return this.evenements.map(evenement => ({
        ...evenement,
        actions: [
          { label: this.$t('tasks.create_task'), payload: { name: 'task-create', params: { model_object: evenement } } },
          { label: this.$t('evenements.delete_evenement'), payload: { name: 'evenement-delete', params: { id: evenement.id } } }
        ],
        is_favorite: !!this.favorites && !!this.favorites['evenement'] &&
          this.favorites['evenement'].some(favorite => favorite.id === evenement.id),
        model: { model_type: 'evenement', model_id: evenement.id, label: evenement.model.label }
      }))
    }
  },

  methods: {
    onMenuClick ({ name, params }) {
      if (name === 'evenement-delete') {
        this.$emit('delete', params.id)
      } else {
        this.$router.push({ name, params })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>

<template>
  <form v-if="formFields" @submit.prevent.stop="save" class="fit">
    <q-card flat bordered class="q-pa-sm">
      <q-card-section>
        <base-input-text v-bind="formInputProps('label')" :color="color" v-model="formData.label" ref="label" />
      </q-card-section>
      <q-card-section>
        <base-input-text v-bind="formInputProps('description')" :color="color" v-model="formData.description" ref="description" />
      </q-card-section>

      <q-card-section class="row items-end q-gutter-xl">
        <q-item class="q-pa-none col">
          <base-input-date v-bind="formInputProps('date')" :color="color" v-model="formData.date" ref="date" />
        </q-item>

        <q-item class="q-pa-none col">
          <base-input-select v-bind="formInputProps('source')" :color="color" v-model="formData.source" ref="source" />
        </q-item>
      </q-card-section>

      <q-card-section class="row items-end">
        <q-item class="q-pa-none col">
          <base-input-boolean v-bind="formInputProps('impact_cible')" :color="color" v-model="formData.impact_cible" ref="impact_cible" />
        </q-item>

        <div class="col col-auto q-gutter-sm">
          <q-btn :color="color" size="sm" outline @click.native="reset">{{$t('form.cancel')}}</q-btn>
          <q-btn :color="color" size="sm" unelevated type="submit">{{$t('form.save')}}</q-btn>
        </div>
      </q-card-section>
    </q-card>
  </form>
</template>

<script>
import { BaseForm } from '../../mixins'

export default {
  name: 'EvenementForm',
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'evenements' },
    'action': { type: String, default: 'evenements/saveEvenement' },
    'color': { type: String, default: 'primary' },
    'model_type': { type: String, required: true },
    'model_id': { type: [String, Number], required: true }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData = {
          ...this.formData,
          model_type: this.model_type,
          model_id: this.model_id
        }
      },
      immediate: true
    }
  },

  mounted () {
    if (!this.formFields) {
      this.$store.dispatch('evenements/fetchFormFields')
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>

<template>
  <base-card v-bind="evenement" v-on="$listeners">
    <q-avatar slot='avatar' size="5rem" :text-color="color" icon="event_available" />

    <div class="row justify-between items-center">
      <q-item-label caption>{{$formatDate(evenement.date)}}</q-item-label>
      <q-icon v-if="evenement.impact_cible" size="24px" color="grey-6" class="q-px-md q-py-none" name="flag" />
    </div>

    <div class="row justify-between">
      <div class="col">
        <router-link :to="evenement.link" v-if="evenement.link">
          <q-item-label class="text-bold">{{evenement.label}}</q-item-label>
        </router-link>
        <q-item-label class="text-bold" v-else>{{evenement.label}}</q-item-label>
      </div>
      <q-item-label caption class="col">{{evenement.model.label}}</q-item-label>
    </div>

    <q-expansion-item group="evenements" caption dense expand-icon-toggle header-class="row q-pa-none items-end">
      <template v-slot:header>
        <q-item-label class="col">{{evenement.auteur.display_name}}</q-item-label>
        <q-item-label class="col">{{evenement.source}}</q-item-label>
      </template>

      <template v-slot:default>
        <div class="q-mt-md" v-if="evenement.description">
          <div class="text-reseau">{{ $t('entities.evenement.description') }}</div>
          <div class="q-ml-sm">
            {{ evenement.description }}
          </div>
        </div>
        <div class="q-mt-md" v-if="evenement.lien_type === 'sujet' && evenement.compte_rendu">
          <div class="text-reseau">{{ $t('entities.evenement.compteRendu') }}</div>
          <div class="q-ml-sm">
            {{ evenement.compte_rendu }}
          </div>
        </div>
      </template>
    </q-expansion-item>
  </base-card>
</template>

<script>
export default {
  name: 'EvenementCard',
  props: ['evenement', 'menuOptions', 'color']
}
</script>

<style>
</style>
